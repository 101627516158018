<div fxLayout="row" fxLayoutAlign="space-between center">

    <mat-form-field style="width: 100%">

        <div fxLayout="row" fxLayoutAlign="space-between center">

            <mat-icon (click)="clearFilter()" matTooltip="{{searchInput === '' ? '' : 'Limpiar filtro de búsqueda'}}"
                [ngStyle]="{'cursor': searchInput === '' ? 'auto' : 'pointer'}">
                {{searchInput.length === 0 ? 'search' : 'close'}}
            </mat-icon>

            <!-- Filtro de búsqueda -->
            <input matInput [value]="searchInput" (keyup)="onKeyUp($event.target.value)" autocomplete="off">
        </div>
    </mat-form-field>
</div>