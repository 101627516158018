<ng-container *transloco="let e; read: 'setting'">
    <div class="center-30">
        <h2 fxLayoutAlign="center center">{{e('configuraciones')}}</h2>
        <mat-list>
            <mat-card class="full-width" fxFlex>
                <mat-list-item class="padding-bottom">
                    <div>{{e('idioma')}}</div>
                    <div class="aling-radio">
                        <mat-form-field>
                            <mat-label >{{e('select-idioma')}}</mat-label>
                            <mat-select [(value)]="selectedLanguage">
                                <mat-option value="es" (click)="updateLangEs()">{{e('español')}}</mat-option>
                                <mat-option value="en"(click)="updateLangEn()">{{e('ingles')}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item class="padding-top">
                    <div>{{e('tema')}}</div>
                    <div class="aling-radio">
                        <mat-form-field>
                            <mat-label>{{e('select-tema')}}</mat-label>
                            <mat-select [(value)]="selectedTheme">
                                <mat-option value="light-theme" (click)="onSetThemeLight()">{{e('light')}}</mat-option>
                                <mat-option value="dark-theme" (click)="onSetThemeDark()">{{e('dark')}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-list-item>
            </mat-card>
        </mat-list>
    </div>
</ng-container>
