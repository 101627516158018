import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

// Settings de la aplicación

const dialogConfig = new MatDialogConfig();

@Injectable()
export class DialogInitService {

    constructor(public _dialog: MatDialog) {
    }

    dialogInit(
                DialogComponent: any,
                data: any,
                width: string = null,
                height: string = null) {

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.hasBackdrop = true;
        dialogConfig.width = width;
        dialogConfig.maxWidth = '100%';
        dialogConfig.maxHeight = '100%';
        dialogConfig.height = height;
        dialogConfig.data = data;

        const dialogRef = this._dialog.open(DialogComponent, dialogConfig);

        return dialogRef;
    }
}
