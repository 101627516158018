import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from './authentication.service';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ChangeTheme } from '../services/changeTheme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TranslocoService } from '@ngneat/transloco';



@Component({
    selector: 'app-verificar',
    templateUrl: './verificar.component.html',
    styleUrls: ['./login.component.scss']
})

export class VerificarComponent implements OnInit {

    snackbarAlert: any; // Alertas con Snackbar
    loginForm: FormGroup;
    forgotForm: FormGroup;
    validateForm: FormGroup;
    submitted = false;
    returnUrl: string;
    username = this.route.snapshot.paramMap.get('username');
    password = this.route.snapshot.paramMap.get('password');
    @HostBinding('class') componentCssClass: any;
    hide = true;
    hide_pass = true;
    constructor(
        public snackBar: MatSnackBar,
        public router: Router,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public translateService: TranslocoService,
        public overlayContainer: OverlayContainer,
        changetheme: ChangeTheme
    ) {
        changetheme.changeEmitted$.subscribe(data => {
            this.onSetTheme(data);
        })
    }
    mensajeError: string;
    
    ngOnInit() {
        this.generarTraducciones();
        this.validateForm = this.formBuilder.group({
            email: [{ value: this.username, disabled: true }, [Validators.required]],
            codigo: [{ value: this.password, disabled: true }, [Validators.required]],
            password: [null, [Validators.required]],
            repetir: [null, [Validators.required]]
        });

        //theme
        if (localStorage.getItem('theme')) {
            this.onSetTheme(localStorage.getItem('theme'));
        }
    }

    @ViewChild('formDirective', { static: false }) formDirective;
    public form: FormGroup;


    resetPassword() {
        let body = {
            username: this.username,
            oldPassword: this.password,
            password: this.validateForm.value.password,
            repetir: this.validateForm.value.repetir,
        }
        if (this.validateForm.valid && this.validateForm.value.password === this.validateForm.value.repetir) {
            this.authenticationService.validate(body)
                .pipe(first())
                .subscribe(
                    res => {
                        this.login();

                    },
                    error => {
                        this.snackBar.open("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", "X", { duration: 4000 });
                    });
        } else {
            this.snackBar.open('ERROR: ' + this.mensajeError, "X", { duration: 4000 });
        }
    }

    login() {
        this.router.navigate(['/login']);
    }

    //change theme
    public onSetTheme(e: string) {
        if (e == 'dark-theme') {
            this.overlayContainer.getContainerElement().classList.remove('light-theme');
            this.overlayContainer.getContainerElement().classList.add('dark-theme');
        } else {
            this.overlayContainer.getContainerElement().classList.remove('dark-theme');
            this.overlayContainer.getContainerElement().classList.add('light-theme');
        }
        this.componentCssClass = e;
    }

    generarTraducciones() {
        this.translateService.selectTranslate('invalidPassword').subscribe((traduccion) => {
            this.mensajeError = traduccion;
        });
    }
}
