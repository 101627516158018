import { Component, OnInit } from '@angular/core';
import { AyudaService } from './ayuda.service';
var pjson = require('./../../../package.json');
@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.scss'],
  providers: [AyudaService]
})
export class AyudaComponent implements OnInit {

  constructor(public ayudaService: AyudaService) { }
  spinner: Boolean = true;
  sistema = '0.0.1';
  sistFront = pjson;
  ngOnInit(): void {
  }

}
