import { TranslocoService } from '@ngneat/transloco';
import { SidenavService } from './../sidenav.service';
import { Component, ElementRef, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogInitService } from "src/app/services/dialog.service";
import { Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    templateUrl: 'notificaciones.component.html',
    styleUrls: ['../sidenav.component.scss'],
    providers: [SidenavService]
})
export class NotificacionesDialogComponent implements OnInit{

    rol = localStorage.getItem('rol');
    public notificacionesUsuarios = null;
    public notificacionesAdmin = null;
    spinner: Boolean = false;
    formatoFecha;

    constructor (
        @Inject(MAT_DIALOG_DATA) public data: any,
        public snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<NotificacionesDialogComponent>,
        public dialogInitService: DialogInitService,
        public sidenavService: SidenavService,
        public router: Router,
        public translateService: TranslocoService
        ){}

    ngOnInit(): void {
        this.formInit();
        this.generarTraducciones();
    }

    formInit() {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    generarTraducciones(){
      this.translateService.langChanges$.subscribe( lang => {
        if (lang == 'es') {
          this.formatoFecha = 'dd/MM/yyyy';
        } else {
          this.formatoFecha = 'MM/dd/yyyy';
        }
      });
    }
}