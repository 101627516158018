import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeTheme } from '../services/changeTheme.service';
import { AuthenticationService } from './authentication.service';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    @HostBinding('class') componentCssClass: any;
    snackbarAlert: any; // Alertas con Snackbar
    loginForm: FormGroup;
    invalidCredentials: Boolean = false;
    checkingUserCredentials: Boolean = false;
    submitted = false;
    returnUrl: string;
    nametheme: string;
    hide = true;
    constructor(
        public snackBar: MatSnackBar,
        public router: Router,
        private el: ElementRef,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public overlayContainer: OverlayContainer,
        private authenticationService: AuthenticationService,
        changetheme: ChangeTheme
    ) {
        changetheme.changeEmitted$.subscribe(data => {
            this.onSetTheme(data);
        })

    }

    ngOnInit() {
        localStorage.removeItem('currentUser');
        this.loginForm = this.formBuilder.group({
            username: [null, [Validators.required]],
            password: [null, [Validators.required]]
        });

        const usernameControl = this.el.nativeElement.querySelector('[formControlName="' + 'username' + '"]');
        usernameControl.focus();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login' || '/';

        //theme
        if (localStorage.getItem('theme')) {
            this.onSetTheme(localStorage.getItem('theme'));
        }
    }

    @ViewChild('formDirective', { static: false }) formDirective;
    public form: FormGroup;


    //change theme
    public onSetTheme(e: string) {
        if (e == 'dark-theme') {
            this.overlayContainer.getContainerElement().classList.remove('light-theme');
            this.overlayContainer.getContainerElement().classList.add('dark-theme');
        } else {
            this.overlayContainer.getContainerElement().classList.remove('dark-theme');
            this.overlayContainer.getContainerElement().classList.add('light-theme');
        }
        this.componentCssClass = e;
    }

    public onSubmit() {

        this.submitted = true;

        if (this.loginForm.valid) {
            this.checkingUserCredentials = true;
            this.authenticationService.login(this.f.username.value.toLowerCase(), this.f.password.value)
                .pipe(first())
                .subscribe(
                    data => {
                        
                        if (data.chgpass != 'S') {
                            this.router.navigate(['/facturas']);
                            this.invalidCredentials = false;
                            this.checkingUserCredentials = false;
                        } else {
                            this.verificar();
                        }
                    },
                    error => {
                        this.checkingUserCredentials = false;
                        this.formDirective.resetForm();
                        this.loginForm.reset();
                        if (error.status) {
                            // this.snackBar.open("Usuario o contraseña incorrecta", "X", { duration: 4000 });
                            this.loginForm.get('password').markAsTouched();
                            this.invalidCredentials = true;
                        }
                    });
        } else {
        }
    }

    verificar() {
        this.router.navigate(['/verificar', { username: this.loginForm.value.username }]);
    }

    get f() { return this.loginForm.controls; }

    olvido() {
        this.snackBar.open("Por favor solicite al administrador del Portal de Proveedores el reinicio de su clave.", "X", { duration: 4000 });
    }
}
