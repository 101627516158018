<div fxLayout="row">
  <div style="margin-left: 20px;">
    <ngx-avatar *ngIf="data.foto_perfil" src="{{ data?.foto_perfil }}"></ngx-avatar>
  </div>
  <div>
    <h3> {{ data.titulo }} </h3>
    {{ data.fecha_alta | date: formatoFecha }}
  </div>
</div>
<br>
{{ data.descripcion }}
<br>
<br>
<div *ngIf="data.ruta" fxLayoutAlign="end end">
  <button class="mat-button-wrapper" color="accent" (click)="descargar()" mat-raised-button>
    Descargar adjunto
  </button>
</div>