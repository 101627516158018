import { Component, OnInit, HostBinding } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeTheme } from '../services/changeTheme.service';

@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.scss']
})
export class ConfiguracionesComponent implements OnInit {

  @HostBinding('class') componentCssClass: any;
  selectedLanguage;
  selectedTheme;
  constructor(public translateService: TranslocoService,
    public overlayContainer: OverlayContainer,
    private changetheme: ChangeTheme ) {
  }

  ngOnInit(): void {
    this.selectedTheme = localStorage.getItem('theme');
    this.selectedLanguage = this.translateService.getActiveLang();
  }

  //Selector de idioma
  updateLangEs() {
    localStorage.setItem('activeLanguage', 'es');
    this.translateService.setActiveLang('es');
  }

  updateLangEn() {
    localStorage.setItem('activeLanguage', 'en');
    this.translateService.setActiveLang('en');
  }

  //theme dark/light
  public onSetThemeDark() {
    localStorage.setItem('theme', 'dark-theme')
    this.changetheme.emitChange('dark-theme')
  }
  public onSetThemeLight() {
    localStorage.setItem('theme', 'light-theme')
    this.changetheme.emitChange('light-theme')
  }
}
