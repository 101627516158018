import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
    public url = environment.baseUrl;
    constructor(private http: HttpClient, public snackBar: MatSnackBar,
        public router: Router) {
    }

    login(username: string, password: string) {

        let loginDetails = {
            username: username,
            password: password
        };

        return this.http.post<any>(this.url + "prov/login", loginDetails)
            .pipe(map(user => {

                // Login successful if there's a JWT token in the response
                if (user && user.token) {

                    let token = {
                        token: user.token
                    }
                    const currentUser = JSON.stringify(token.token)


                    localStorage.setItem('currentUser', currentUser);
                    localStorage.setItem('iduserppc', user.iduserppc);
                    localStorage.setItem('descprov', user.descprov);
                    localStorage.setItem('userppc', user.userppc);
                    localStorage.setItem('idimagenemp', user.idimagenemp);
                    
                }
                return user;
            }));
    }

    logout() {
        this.sidenav?.close();
        this.router.navigate(['/login']);
        localStorage.removeItem('currentUser');
        localStorage.clear();
    }

    validate(body) {
        let pass = {
            "iduserppc": localStorage.getItem('iduserppc'),
            "newpass": body.password
        }

        return this.http.put<any>(this.url + "consul/login/resetpass", pass)
            .pipe(map(res => {
                return res;
            }));
    }



}
