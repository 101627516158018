import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { AyudaService } from './ayuda.service';
import { AyudaComponent } from './ayuda.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule
  ],
  declarations: [
    AyudaComponent,
  ],  
  exports: [
    MatTableModule,
    MatPaginatorModule
  ],
  entryComponents:[
  ],
  providers: [
    AyudaService
  ]
})

export class AyudaModule { }
