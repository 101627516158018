import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
    selector: 'scroll-top',
    templateUrl: './scroll-top.html',
    styleUrls: ['./scroll-top.scss']
})
export class ScrollTopComponent implements OnInit {
    windowScrolled: Boolean = false;
    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    ngOnInit(): void {

        window.addEventListener('scroll', () => {
            this.windowScrolled = window.pageYOffset !== 0;
        });

    }

    scrollToTop(): void {
        return this.document.body.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        });
    }

}