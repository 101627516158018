import { TranslocoService } from '@ngneat/transloco';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardService } from '../dashboard.service';

@Component({
    templateUrl: 'message.component.html',
    styleUrls: ['../dashboard.component.scss'],
})

export class DashboardMessageDialogComponent {

    formatoFecha;

    constructor(private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dashboardService: DashboardService,
        public snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<DashboardMessageDialogComponent>,
        public translateService: TranslocoService) {            
    }

    closeDialog() {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.translateService.langChanges$.subscribe( lang => {
            if (lang == 'es') {
              this.formatoFecha = 'dd/MM/yyyy';
            } else {
              this.formatoFecha = 'MM/dd/yyyy';
            }
          });
    }
}