import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslocoService } from "@ngneat/transloco";


@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {

  traducciones;

  constructor(private translateService: TranslocoService) {
    super();
    this.translateService.langChanges$.subscribe( res => {
      this.translateLabels();
    });
    this.translateLabels();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    let of: string;
    this.translateService.selectTranslate('paginador.of').subscribe(res => {
      of = res;
    });
    if (length === 0 || pageSize === 0) {
      return "0 " + of + " " + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);

    return startIndex + 1 + " - " + endIndex + " " + of + " " + length;
  };

  translateLabels(): void {

    this.translateService.selectTranslate('paginador.firstPageLabel').subscribe(res =>{
      this.firstPageLabel = res;
    });
    this.translateService.selectTranslate('paginador.itemsPerPageLabel').subscribe(res =>{
      this.itemsPerPageLabel = res;
    });
    this.translateService.selectTranslate('paginador.lastPageLabel').subscribe(res =>{
      this.lastPageLabel = res;
    });
    this.translateService.selectTranslate('paginador.nextPageLabel').subscribe(res =>{
      this.nextPageLabel = res;
    });
    this.translateService.selectTranslate('paginador.previousPageLabel').subscribe(res =>{
      this.previousPageLabel = res;
    });
    this.changes.next(); // Fire a change event to make sure that the labels are refreshed
  }
}
