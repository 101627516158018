import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AyudaComponent } from './ayuda/ayuda.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FacturasComponent } from './facturas/facturas.component';
import { LoginComponent } from './login/login.component';

export const userRoutes = [
  {
    path: 'facturas',
    component: FacturasComponent,
    label: 'Facturas',
    icon: 'receipt_long'
  },
  {
    path: 'settings',
    component: ConfiguracionesComponent,
    label: 'Configuraciones',
    icon: 'settings'
  },
  {
    path: 'ayuda',
    component: AyudaComponent,
    label: 'Ayuda',
    icon: 'help_outline'
  },
  {
    path: 'login',
    component: LoginComponent,
    label: 'Login',
    icon: 'logout'
  }
  

];

@NgModule({
  imports: [RouterModule.forRoot(userRoutes)],
  exports: [RouterModule]
})

export class UserRoutingModule { }

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(userRoutes, {

  preloadingStrategy: PreloadAllModules, // Comment to allow lazy loading
  onSameUrlNavigation: 'ignore',
  useHash: true // Comment to avoid # prefix on the URL
});
