<!---------------------------------------------------------- Barra de opciones de la tabla -->
<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutAlign="end end">
      <button mat-icon-button class="onlyWeb" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <button mat-icon-button class="hiddenIcon" (click)="closeDialog()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h3 class="table-header"> {{ 'facturas.detalle_dialog.titulo' | transloco }}</h3>
    </div>
    <!-- WEB -->
    <div fxShow fxHide.lt-md>
        <mat-table #table id="dataSourceGrid" [dataSource]="dataSource" matSort>

            <!-- Columnas de la tabla  -------------------------------------------------------------->

            <ng-container matColumnDef="codtran_regpag">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.codtran' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.codtran_regpag }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="numtran_regpag">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header">{{ 'facturas.detalle_dialog.numtran' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.numtran_regpag }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fecpagoe_regpag">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.fecreg' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.fecpagoe_regpag }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="detalle_regpag">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'facturas.detalle_dialog.detalle' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura"> {{ factura.detalle_regpag }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="codtran_facreg">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.codtran' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.codtran_facreg }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="numtran_facreg">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header">{{ 'facturas.detalle_dialog.numtran' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.numtran_facreg }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fecreg_facreg">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.fecreg' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.fecreg_facreg }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="detalle_facreg">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'facturas.detalle_dialog.detalle' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura"> {{ factura.detalle_facreg }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ordpago_ordpaggen">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.ordpago' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.ordpago_ordpaggen }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fecpago_ordpaggen">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.fecpago' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.fecpago_ordpaggen }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="detalle_ordpaggen">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'facturas.detalle_dialog.detalle' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura"> {{ factura.detalle_ordpaggen }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="autorizado_ordpaggen">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header">
                    {{ 'facturas.detalle_dialog.autorizado' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data">
                    {{ 'facturas.detalle_dialog.' + factura.autorizado_ordpaggen | transloco }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fecaut_ordpaggen">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.fecaut' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.fecaut_ordpaggen }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ordpago_ordpagaut">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.ordpago' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.ordpago_ordpagaut }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fecpago_ordpagaut">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.fecpago' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.fecpago_ordpagaut }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="detalle_ordpagaut">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'facturas.detalle_dialog.detalle' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura"> {{ factura.detalle_ordpagaut }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="autorizado_ordpagaut">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.autorizado' | transloco
                    }}
                </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.autorizado_ordpagaut }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fecaut_ordpagaut">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-table-header"> {{ 'facturas.detalle_dialog.fecaut' | transloco }}
                    </mat-header-cell>
                <mat-cell *matCellDef="let factura" class="mat-table-data"> {{ factura.fecaut_ordpagaut }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="buttons">
                <!-- Columna editar -->
                <mat-header-cell *matHeaderCellDef class="pl-3 pr-3 mxw-30" class="mat-table-buttons">
                    <ng-container *ngIf="!dataSourceEmpty">{{ 'facturas.download' | transloco }}</ng-container>
                </mat-header-cell>
                <mat-cell class="mat-table-buttons" *matCellDef="let row">
                    <button mat-icon-button (click)="detalle(row)" *ngIf="row.existedoc_regpag=='S'">
                        <mat-icon>file_download</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

            <!-------------------------------------------------------------- Columnas de la tabla -->
        </mat-table>

        <!-- Paginador -->
        <mat-paginator class="fh-color" #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 75, 100]"
            [showFirstLastButtons]="true">
        </mat-paginator>
    </div>

    <!-- MOBILE -->
    <div fxHide fxShow.lt-md>
        <div *ngFor="let factura of data?.detfactura" class="mat-table-data">

            <!-- CASO 1 -->
            <div *ngIf="displaycase==1">
                <mat-card style="margin-bottom: 20px !important;">
                    <mat-list>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.codtran' | transloco }}</div>
                                <div>{{ factura.codtran_facreg }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.numtran' | transloco }}</div>
                                <div>{{ factura.numtran_facreg }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.fecreg' | transloco }}</div>
                                <div>{{ factura.fecreg_facreg }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.detalle' | transloco }}</div>
                                <div>{{ factura.detalle_facreg }}</div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag=='S'">
                        <button color="primary" type="button" (click)="detalle(factura)" mat-raised-button
                            style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag!='S'">
                        <button color="primary" type="button" disabled mat-raised-button style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                </mat-card>
            </div>

            <!-- CASO 2 -->
            <div *ngIf="displaycase==2">
                <mat-card style="margin-bottom: 20px !important;">
                    <mat-list>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.ordpago' | transloco }}</div>
                                <div>{{ factura.ordpago_ordpaggen }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.fecpago' | transloco }}{{ 'facturas.detalle_dialog.fecpago' | transloco }}</div>
                                <div>{{ factura.fecpago_ordpaggen }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.detalle' | transloco }}</div>
                                <div>{{ factura.detalle_ordpaggen }}</div>
                            </div>
                        </mat-list-item>
                        <!-- <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem"> {{ 'facturas.detalle_dialog.autorizado' | transloco}}</div>
                                <div>{{ factura.autorizado_ordpaggen }}</div>
                            </div>
                        </mat-list-item> -->
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem"> {{ 'facturas.detalle_dialog.fecaut' | transloco }}</div>
                                <div>{{ factura.fecaut_ordpaggen }}</div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag=='S'">
                        <button color="primary" type="button" (click)="detalle(factura)" mat-raised-button
                            style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag!='S'">
                        <button color="primary" type="button" disabled mat-raised-button style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                </mat-card>
            </div>

            <!-- CASO 3 -->
            <div *ngIf="displaycase==3">
                <mat-card style="margin-bottom: 20px !important;">
                    <mat-list>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.ordpago' | transloco }}</div>
                                <div>{{ factura.ordpago_ordpagaut }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.fecpago' | transloco }}</div>
                                <div>{{ factura.fecpago_ordpagaut }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.detalle' | transloco }}</div>
                                <div>{{ factura.detalle_ordpagaut }}</div>
                            </div>
                        </mat-list-item>
                        <!-- <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem"> {{ 'facturas.detalle_dialog.autorizado' | transloco}}</div>
                                <div>{{ factura.autorizado_ordpagaut }}</div>
                            </div>
                        </mat-list-item> -->
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem"> {{ 'facturas.detalle_dialog.fecaut' | transloco }}</div>
                                <div>{{ factura.fecaut_ordpagaut }}</div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag=='S'">
                        <button color="primary" type="button" (click)="detalle(factura)" mat-raised-button
                            style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag!='S'">
                        <button color="primary" type="button" disabled mat-raised-button style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                </mat-card>
            </div>

            <!-- CASO 4 -->
            <div *ngIf="displaycase==4">
                <mat-card style="margin-bottom: 20px !important;">
                    <mat-list>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.codtran' | transloco }}</div>
                                <div>{{factura.codtran_regpag}}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.numtran' | transloco }}</div>
                                <div>{{ factura.numtran_regpag }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.fecreg' | transloco }}</div>
                                <div>{{ factura.fecpagoe_regpag }}</div>
                            </div>
                        </mat-list-item>
                        <mat-list-item>
                            <div fxLayout="column">
                                <div class="titleItem">{{ 'facturas.detalle_dialog.detalle' | transloco }}</div>
                                <div>{{ factura.detalle_regpag }}</div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag=='S'">
                        <button color="primary" type="button" (click)="detalle(factura)" mat-raised-button
                            style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                    <div fxLayoutAlign="end end" *ngIf="factura.existedoc_regpag!='S'">
                        <button color="primary" type="button" disabled mat-raised-button style="margin-top: 15px">
                            {{ 'facturas.download' | transloco }}
                        </button>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <!-- <div fxLayout="column" fxLayoutAlign="end center" class="onlyWeb" style="margin-top: auto !important;">
        <button class="button-wrapper onlyWeb" color="accent" type="button" mat-raised-button (click)="closeDialog()">
            {{ 'facturas.detalle_dialog.aceptar' | transloco }}
        </button>
    </div> -->
</div>
