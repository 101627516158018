import { Component, Input } from '@angular/core';

@Component({
    selector: 'rafiki-illustrations-nodata',
    templateUrl: './svg/nodata.component.html'
})
export class RafikiNoDataComponent {

    @Input() maxSize: number = 450;

    get svgMaxSize() {
        return 'max-height: ' + this.maxSize.toString() + 'px';
    }
}

/*@Component({
    selector: 'rafiki-illustrations-x',
    templateUrl: './rafiki-illustrations-x.component.html'
})
export class RafikiXaComponent {}*/