import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { FacturasService } from './facturas.service';
import { FacturasComponent } from './facturas.component';
import { DetalleDialogComponent } from './detalle/detalle.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule
  ],
  declarations: [
    FacturasComponent,
    DetalleDialogComponent
  ],  
  exports: [
    MatTableModule,
    MatPaginatorModule
  ],
  entryComponents:[
    DetalleDialogComponent
  ],
  providers: [
    FacturasService
  ]
})

export class FacturasModule { }
