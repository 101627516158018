import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FacturasService } from './facturas.service';
import { Animations } from './../services/forms.animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { DetalleDialogComponent } from './detalle/detalle.component';
import { DialogInitService } from '../services/dialog.service';
import { ExcelService } from '../shared/services/xlsx.service';

//dimensiones del dialog
let dialogWidth;
let dialogHeight;
if(screen.width < 800){
  dialogWidth = '100vw';
  dialogHeight = '100vh';
}else{
  dialogWidth = '140vh';
  dialogHeight = '70vh';
}
@Component({
  animations: Animations.getAll,
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss'],
  providers: [FacturasService]
})
export class FacturasComponent implements OnInit {
  // Filas a visualizar en la tabla
  displayedColumns = [
    'buttons',
    'factura',
    'fecemi',
    'fecvenc',
    'moneda',
    'importe',
    'descestado',
  ];
  formGroup = new FormGroup({});
  dataSource: any; // Fuente de los datos de la tabla
  filterState: FormGroup; // Filtro de la tabla
  dataSourceEmpty: boolean = false; // Flag para tablas vacías
  searchVisible: boolean = false; // Valor default de la visibilidad del input de búsqueda
  selection = new SelectionModel<any>(true, []); // Selección de archivos a borrar
  tableToXLSX: any[] = [];
  filterValue: string = '';
  filteredValue: string = '';
  currentlyEditing;

  // Material table sort
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // Material table paginator
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  traducciones: string[];
  estadosList;
  constructor(public facturasService: FacturasService,
    public snackBar: MatSnackBar,
    public dialogInitService: DialogInitService,
    private formBuilder: FormBuilder,
    public xlsxService: ExcelService,
    public translateService: TranslocoService) { }

  ngOnInit(): void {
    this.formInit();
    this.generarTraducciones();
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  formInit() {
    this.selection.clear();
    this.formGroup = this.formBuilder.group({
      fecdesde: [null],
      fechasta: [null],
      idestado: [null],
    });

    this.facturasService.getEstados().subscribe(res => {
      this.estadosList = res.estados;
    })

    let facturas = {
      fecdesde: this.formGroup.value.fecdesde,
      fechasta: this.formGroup.value.fechasta,
      idestado: this.formGroup.value.idestado,
    }

    this.facturasService.getFacturas(facturas).subscribe(
      response => {
        this.tableToXLSX = response.facturas;
        this.dataSource = new MatTableDataSource<any>(response.facturas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        if (this.dataSource.data.length > 0) {
          this.dataSourceEmpty = false;
          this.searchVisible = false;
        } else
          this.dataSourceEmpty = true;
      },
      err => {
        this.dataSource = new MatTableDataSource<any>([]);
        this.dataSourceEmpty = true;
        this.snackBar.open(this.traducciones['error'], "X", { duration: 4000 });
      }
    )
  }

  refresh(){
    this.filterValue = '';
    this.filteredValue = '';
    this.formInit();
  }

  getFacturas() {
    let desde = moment(this.formGroup.value.fecdesde);
    let hasta = moment(this.formGroup.value.fechasta);

    if(desde > hasta){

      this.formInit();
      this.snackBar.open(this.traducciones['fechas'], "X", { duration: 4000 });
    } else {

      let facturas = {
        fecdesde: this.formGroup.value.fecdesde ? moment(this.formGroup.value.fecdesde).format("DD/MM/yyyy") : null,
        fechasta: this.formGroup.value.fechasta ? moment(this.formGroup.value.fechasta).format("DD/MM/yyyy") : null,
        idestado: this.formGroup.value.idestado,
      }
      
      this.facturasService.getFacturas(facturas).subscribe(
        response => {
          this.tableToXLSX = response.facturas;
          this.dataSource = new MatTableDataSource<any>(response.facturas);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          if (this.dataSource.data.length > 0) {
            this.dataSourceEmpty = false;
            this.searchVisible = false;
          } else
            this.dataSourceEmpty = true;
        },
        err => {
          this.dataSource = new MatTableDataSource<any>([]);
          this.dataSourceEmpty = true;
          this.snackBar.open(this.traducciones['error'], "X", { duration: 4000 });
        }
      )
    }
  }

  generarTraducciones() {
    this.translateService.selectTranslateObject('facturas.errores').subscribe((traduccion) => {
      this.traducciones = traduccion;
    });
  }

  // Botón agregar de la tabla
  addFactura(): void {

  }

  // Botón remover registro de la tabla
  deleteFactura(): void {

  }

  // Función para refrescar la tabla
  refreshDataSource(): void {

  }

  // Botón para visualizar el input de búsqueda
  toggleSearchInput(): void {
    this.searchVisible = !this.searchVisible;
  }

  // Evento que se emite cada vez que se detectan cambios en el input de búsqueda
  applyFilter(filterValue) {
    this.filteredValue = filterValue;
    this.dataSource.filter = filterValue.value.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0)
      this.dataSourceEmpty = true; else
      this.dataSourceEmpty = false;
  }

  jasperPrint() {

  }

  detalle(item): void {
    this.facturasService.getDetalle(item.idfactura).subscribe(res => {
      this.dialogInitService.dialogInit(DetalleDialogComponent, res, dialogWidth, dialogHeight).
      afterClosed().subscribe(result => {this.applyFilter(this.filteredValue)});
    })

  }

  isAllSelected(dataSource: any, selectionModel: SelectionModel<any>) {

    const numSelected = selectionModel.selected.length;
    const numRows = dataSource.data.length;
    return numSelected === numRows;
  }

  isSomeSelected(selectionModel: SelectionModel<any>) {

    return selectionModel.selected.length > 0;
  }

  // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle(ref, selectionModel: SelectionModel<any>, dataSource: any) {

    // If there is a selection then clear that selection
    if (this.isSomeSelected(selectionModel)) { selectionModel.clear(); ref.checked = false; } else {

      this.isAllSelected(dataSource, selectionModel) ?
        selectionModel.clear() :
        dataSource.data.forEach(row => selectionModel.select(row));
    }
  }

      // Imprimir datos de las grillas (se dejan de visualizar las columnas de estado y editar)
      printComponent() {

        // Mostrar solo datos relevantes para el usuario
        let headers = [[ "Factura", "Emisión", "Vencimiento", "Moneda", "Importe", "Estado" ]];
        let cleanOutput = this.tableToXLSX.map(function (table) {
          return {
              'Factura': table.factura,
              'Emisión': table.fecemi,
              'Vencimiento': table.fecvenc,
              'Moneda': table.moneda,
              'Importe': table.importe,
              'Estado': table.descestado,
          }
      });

      this.xlsxService.exportAsPDFFile(headers, cleanOutput, 'lista_facturas');
    }

    // Exportar tabla a formato XLSX
    exportToXLSX(): void {
        // Mostrar solo datos relevantes para el usuario
        let cleanOutput = this.tableToXLSX.map(function (table) {
            return {
                'Factura': table.factura,
                'Emisión': table.fecemi,
                'Vencimiento': table.fecvenc,
                'Moneda': table.moneda,
                'Importe': table.importe,
                'Estado': table.descestado,
            }
        });
        this.xlsxService.exportAsExcelFile(cleanOutput, 'lista_facturas');
    }

    // Filtro búsqueda para mobile
    searchInput: string; onSearchInputValueEmitted(value: string) {
      this.searchInput = value;
    }
}
