<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 1 : 0"
    [hasBackdrop]="mobileQuery.matches ? true : false"
  >
    <mat-sidenav
      #sidenav
      style="width: 235px"
      [mode]="mobileQuery.matches ? 'over' : 'over'"
      [fixedInViewport]="mobileQuery.matches"
    >
      <mat-nav-list>
        <!-- <div fxLayoutAlign="center" style="width: 80px;">
          <img src="https://ordserp19.open.com.ar/erp19/ppcdr/img/emp/{{idimagenemp}}" class="size-logo">
        </div> -->
        <div fxLayoutAlign="center">
          <img class="size-logo" src="https://ordsppc.open.com.ar/ords/ppcdr/img/emp/{{idimagenemp}}">
        </div>
        <!-- <div fxLayoutAlign="center">
            <h5>{{ (descprov?.length>20)? (descprov | slice:0:20)+'...':(descprov) }}</h5>
          </div> -->
        <!-- Menú general -->
        <ng-container *transloco="let e; read: 'label-nav'">
          <ng-container *transloco="let t; read: 'sidenav-nav'">
            <div>
              <br />
              <a
                *ngFor="let route of userRoutes"
                mat-list-item
                routerLinkActive="active"
                [routerLink]="route.path"
              >
                <mat-icon
                  aria-hidden="false"
                  class="iconos"
                  aria-label="Example home icon"
                  >{{ route.icon }}</mat-icon
                >
                &nbsp;&nbsp; {{ e(route.label) }}
              </a>
            </div>
            <div class="logo-condor-link" fxLayoutAlign="start center">
              <div *ngIf="componentCssClass != 'dark-theme'">
                <a
                  href="https://www.opensolutions.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="../../../assets/images/condor_link.png"
                    style="width: 130px"
                  />
                </a>
              </div>
              <div *ngIf="componentCssClass == 'dark-theme'">
                <a
                  href="https://www.opensolutions.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="../../assets/images/condor_link_dark.png"
                    style="width: 130px"
                  />
                </a>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar
        *ngIf="
          nameURL != '/login' && nameURL != '/' && nameURLs != '/verificar'
        "
      >
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>

        <div
          div
          fxLayout="row"
          fxLayoutAlign="end center"
          style="margin-left: auto"
        >
          <!-- Nombre -->
          <!-- <div fxLayout="row" style="margin: 0 20px">
            <div class="display">
              <h5>{{ (descprov?.length>30)? (descprov | slice:0:30)+'...':(descprov) }}</h5>
            </div>
            <div class="hidden nameMobile">
              <div>{{ (descprov?.length>16)? (descprov | slice:0:16)+'...':(descprov) }}</div>
            </div>
          </div> -->

          <!-- Notificaciones -->
          <!-- <ng-container>
            <div fxLayoutAlign="space-around center" class="icon-display" style="margin: 0 8px 0 0px;">
              <button type="button" class="icon-button" (click)="openNotification()" [disabled]="noLeidasAdmin == 0">
                <span class="material-icons" [@swing]="swing">notifications</span>
                <span class="icon-button__badge" *ngIf="noLeidasAdmin > 0">{{noLeidasAdmin}}</span>
              </button>
            </div>
          </ng-container> -->

          <!-- Avatar de usuario -->
          <div style="cursor: pointer">
            <ngx-avatar
              class="display"
              fxLayout="row"
              fxLayoutAlign="space-evenly center"
              size="50"
              name="{{ userppc }}"
              [matMenuTriggerFor]="matMenu"
            >
            </ngx-avatar>
          </div>
          <mat-menu #matMenu="matMenu" xPosition="after">
            <div class="item nombre">
              <b>{{ userppc }}</b>
            </div>
            <div class="item prov">
              <i>{{ descprov }}</i>
            </div>
          </mat-menu>

          <ngx-avatar
            class="hidden"
            fxLayout="row"
            fxLayoutAlign="space-evenly center"
            size="40"
            name="{{ userppc }}"
            [matMenuTriggerFor]="matMenu"
          >
            <!-- <mat-menu #matMenu="matMenu" xPosition="after">
              <div class="item nombre"><b>{{userppc}}</b></div>
              <div class="item prov"><i>{{descprov}}</i></div>
            </mat-menu> -->
          </ngx-avatar>
        </div>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
