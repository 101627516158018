import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable()
export class ChangeTheme {
    constructor() { }

    private emitChangeSource = new Subject<any>();

    changeEmitted$ = this.emitChangeSource.asObservable();

    emitChange(e) {
        this.emitChangeSource.next(e);
    }
}