import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login.component';
import { VerificarComponent } from './verificar.component';

const routes: Routes = [
    { path: '',  component: LoginComponent },
    { path: 'login',  component: LoginComponent },
    { path: 'verificar', component: VerificarComponent }
  ];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        LoginComponent,
        VerificarComponent
    ]
})
export class LoginModule { }