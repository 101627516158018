import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { bounceInY } from 'ng-animate';
import { MatTabBody } from '@angular/material/tabs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const PDF_TYPE = 'application/pdf';
const PDF_EXTENSION = '.pdf';
const REAL_MONTH_COUNT = 1;
@Injectable()
export class ExcelService {
    constructor() { }

    public exportAsExcelFile(json: any[], excelFileName: string, column_size?: any[]): void {

        let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        if (column_size) { let worksheet_cols = column_size; worksheet['!cols'] = worksheet_cols; }
        const workbook: XLSX.WorkBook = { Sheets: { 'Reporte': worksheet }, SheetNames: ['Reporte'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    public exportAsPDFFile(headers, json :any[], excelFileName: string, column_size?: any[]): void {
       
        var doc = new jsPDF();
        let data = json;
        let header = Object.keys(data[0]).filter(key => !headers.includes(key));
        let values = data.map( (elemento) => Object.values(elemento));
       
        autoTable(doc, {
            body: values,
            head: [header],
        });
        
        let today = new Date();
        let day = today.getDate() + '-' + (today.getMonth() + REAL_MONTH_COUNT) + '-' + today.getFullYear()
        doc.save('lista_facturas_' + day + PDF_EXTENSION);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {

        let today = new Date();

        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName.split(' ').join('_') + '_' + today.getDate() + '-' + (today.getMonth() + REAL_MONTH_COUNT) + '-' + today.getFullYear() + EXCEL_EXTENSION);
    }
}