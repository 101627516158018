import { Injectable, ViewChild } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;

    constructor(private authenticationService: AuthenticationService, public snackBar: MatSnackBar, public router: Router) {
        this.sidenav?.close()
    }
    content;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser,
                }
            });
            
        }

        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case 401:
                    this.authenticationService.logout();
                    this.sidenav?.close();
                    break;
                case 404:
                    this.snackBar.open('ERROR: ' + err.status.toString() + ' - ' + "not found", "X", { duration: 4000 });
                    break;
                case 501:
                    this.snackBar.open('ERROR: ' + err.status.toString() + ' - ' + "not implemented", "X", { duration: 4000 });
                    break;
            }

            return throwError(err);
        }))
    }
}
