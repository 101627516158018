import { TranslocoService } from '@ngneat/transloco';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogInitService } from 'src/app/services/dialog.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FacturasService } from '../facturas.service';

@Component({
    templateUrl: 'detalle.component.html',
    styleUrls: ['../facturas.component.scss']
})
export class DetalleDialogComponent implements OnInit {

    translate;
    mensajeError;
    // Filas a visualizar en la tabla
    displayedColumns = [];
    detalles;
    displaycase;

    formGroup = new FormGroup({});
    dataSource: any; // Fuente de los datos de la tabla
    filterState: FormGroup; // Filtro de la tabla
    dataSourceEmpty: boolean = false; // Flag para tablas vacías
    searchVisible: boolean = false; // Valor default de la visibilidad del input de búsqueda
    selection = new SelectionModel<any>(true, []); // Selección de archivos a borrar
    tableToXLSX: any[] = [];
    // Material table sort
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    // Material table paginator
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    numero_factura
    constructor(public snackBar: MatSnackBar, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DetalleDialogComponent>,
        public facturasService: FacturasService,
        public dialogInitService: DialogInitService,
        public translateService: TranslocoService) {
    }

    ngOnInit() {
        if (this.data?.detfactura[0]) {
            this.numero_factura = this.data.detfactura[0].factura;
            this.dataSource = new MatTableDataSource<any>(this.data.detfactura);
            this.generarTraducciones();
            switch (this.data.detfactura[0].estado) {
                case 1: //REGISTRADA
                    this.displayedColumns = [
                        'codtran_facreg',
                        'numtran_facreg',
                        'fecreg_facreg',
                        'detalle_facreg',
                        //'buttons'
                    ];
                    this.displaycase = 1;
                    break;
                case 2: //OP GENERADA
                    this.displayedColumns = [
                        'ordpago_ordpaggen',
                        'fecpago_ordpaggen',
                        'detalle_ordpaggen',
                        'autorizado_ordpaggen',
                        //'fecaut_ordpaggen',
                        //'buttons'
                    ]
                    this.displaycase = 2;
                    break;
                case 3: //OP AUTORIZADA
                    this.displayedColumns = [
                        'ordpago_ordpagaut',
                        'fecpago_ordpagaut',
                        'detalle_ordpagaut',
                        // 'autorizado_ordpagaut',
                        'fecaut_ordpagaut',
                        //'buttons'
                    ];
                    this.displaycase = 3;
                    break;
                case 4:
                    this.displayedColumns = [
                        'codtran_regpag',
                        'numtran_regpag',
                        'fecpagoe_regpag',
                        'detalle_regpag',
                        'buttons'
                    ];
                    this.displaycase = 4;

                    break;
                default:
                    break;
            }
        }
        this.facturasService.getDetalle(this.numero_factura).subscribe(res => {
            this.detalles = res;
        })
    }


    closeDialog() {
        this.dialogRef.close();
    }

    generarTraducciones() {
        this.translateService.selectTranslateObject('facturas').subscribe(traduccion => {
            this.translate = traduccion;
        })
        this.translateService.selectTranslate('facturas.error').subscribe((traduccion) => {
            this.mensajeError = traduccion;
        });
    }

    detalle(row) {
        if (row.idpdf_regpag) {
            this.facturasService.getFactura(row.idpdf_regpag).subscribe(
                res => {
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    var url = window.URL.createObjectURL(res);
                    a.href = url;
                    a.download = String("factura_" + row.idfactura + ".zip");
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                },
                err => {
                    this.snackBar.open(this.mensajeError, "X", { duration: 4000 });
                }
            )
        }
    }
}
