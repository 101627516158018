<div class="center">
  <h2 fxLayoutAlign="start left">{{ "facturas.titulo" | transloco }}</h2>
  <form [formGroup]="formGroup" (ngSubmit)="getFacturas()" id="ngForm">
    <div class="inputsResponsive">
      <ng-container *transloco="let t; read: 'facturas.select'">
        <mat-form-field class="full-width" fxFlex>
          <mat-select
            placeholder="{{ 'facturas.estado' | transloco }}"
            formControlName="idestado"
            class="form-control"
          >
            <mat-option *ngFor="let p of estadosList" [value]="p.idestado"
              >{{ t(p.descripcion) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <mat-form-field class="full-width" fxFlex>
        <input
          matInput
          autocomplete="off"
          [matDatepicker]="picker"
          formControlName="fecdesde"
          placeholder="{{ 'facturas.desde' | transloco }}"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="full-width" fxFlex>
        <input
          matInput
          autocomplete="off"
          [matDatepicker]="picker2"
          formControlName="fechasta"
          placeholder="{{ 'facturas.hasta' | transloco }}"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="end end"
      class="buttons buttons-dialog"
    >
      <button
        class="mat-button-wrapper"
        type="submit"
        (click)="getFacturas()"
        mat-raised-button
        color="primary"
      >
        {{ "facturas.buscar" | transloco }}
      </button>
    </div>
  </form>
  <!-- WEB -->
  <div fxShow fxHide.lt-md>
    <div style="margin-bottom: 20px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field class="mid-width">
          <!-- Filtro de búsqueda -->
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-icon> search </mat-icon>
            <input
              matInput
              (keyup)="applyFilter($event.target)"
              data-cy="search"
              autocomplete="off"
              [(ngModel)]="filterValue"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </mat-form-field>
        <span fxLayoutAlign="end right">
          <button class="m-3" (click)="refresh()" mat-icon-button>
            <!-- Botón: refrescar tabla -->
            <mat-icon>refresh</mat-icon>
          </button>

          <button
            [disabled]="false"
            class="m-3"
            mat-icon-button
            [disabled]="dataSourceEmpty"
            [matMenuTriggerFor]="menu"
          >
            <!-- Botón: más opciones -->
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button
              (click)="exportToXLSX()"
              mat-menu-item
              [disabled]="dataSourceEmpty"
            >
              <!-- Descargar -->
              <mat-icon>cloud_download</mat-icon>
              <span>{{ "facturas.descargar" | transloco }}</span>
            </button>

            <button
              (click)="printComponent()"
              mat-menu-item
              [disabled]="dataSourceEmpty"
            >
              <!-- Imprimir -->
              <mat-icon>cloud_download</mat-icon>
              <span>{{ "facturas.imprimir" | transloco }}</span>
            </button>
          </mat-menu>
        </span>
      </div>

      <!-- TABLA -->
      <mat-table #table id="dataSourceGrid" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="factura">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "facturas.numero" | transloco }}
          </mat-header-cell>
          <mat-cell *matCellDef="let factura"> {{ factura.factura }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fecemi">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "facturas.emision" | transloco }}
          </mat-header-cell>
          <mat-cell *matCellDef="let factura"> {{ factura.fecemi }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fecvenc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "facturas.vencimiento" | transloco }}
          </mat-header-cell>
          <mat-cell *matCellDef="let factura"> {{ factura.fecvenc }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="moneda">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "facturas.moneda" | transloco }}
          </mat-header-cell>
          <mat-cell *matCellDef="let factura"> {{ factura.moneda }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="importe">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-importe"
          >
            {{ "facturas.importe" | transloco }}
          </mat-header-cell>
          <mat-cell *matCellDef="let factura"> {{ factura.importe }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="descestado">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "facturas.estado" | transloco }}
          </mat-header-cell>
          <mat-cell *matCellDef="let factura">
            {{ "facturas.select." + factura.descestado | transloco }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="buttons">
          <!-- Columna editar -->
          <mat-header-cell class="mat-table-buttons" *matHeaderCellDef>
            <ng-container *ngIf="!dataSourceEmpty"
              >{{ "facturas.detalle" | transloco }}
            </ng-container>
          </mat-header-cell>
          <mat-cell class="mat-table-buttons" *matCellDef="let row">
            <button mat-icon-button (click)="detalle(row)">
              <mat-icon fontSet="material-icons-outlined">description</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="select">
          <!-- Columna de seleccionables -->
          <mat-header-cell
            class="mat-table-buttons"
            class="pl-3 pr-3 mxw-30"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-checkbox
              *ngIf="!dataSourceEmpty"
              #ref
              (change)="
                $event ? masterToggle(ref, selection, dataSource) : null
              "
              [checked]="
                selection.hasValue() && isAllSelected(dataSource, selection)
              "
              [indeterminate]="
                selection.hasValue() && !isAllSelected(dataSource, selection)
              "
            >
            </mat-checkbox>
          </mat-header-cell>

          <mat-cell
            class="mat-table-buttons"
            class="pl-3 pr-3 mxw-30"
            id="delete-checkbox"
            mat-cell
            *matCellDef="let row"
          >
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                $event ? selection.toggle(row) : null; isSomeSelected(selection)
              "
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <!-- Paginador -->
      <mat-paginator
        class="fh-color"
        #paginator
        [pageSize]="10"
        [pageSizeOptions]="[10, 25, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
      <br /><br />
    </div>
  </div>

  <!-- MOBILE -->
  <div fxHide fxShow.lt-md>
    <div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Filtro de búsqueda -->
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <search-filter-component
            (inputValue)="onSearchInputValueEmitted($event)"
          >
          </search-filter-component>
        </div>

        <span fxLayoutAlign="end right">
          <button class="m-3" (click)="refresh()" mat-icon-button>
            <!-- [matMenuTriggerFor]="filter_list"> -->
            <!-- Botón: refrescar tabla -->
            <mat-icon>refresh</mat-icon>
          </button>

          <button
            [disabled]="false"
            class="m-3"
            mat-icon-button
            [disabled]="dataSourceEmpty"
            [matMenuTriggerFor]="menu"
          >
            <!-- Botón: más opciones -->
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button
              (click)="exportToXLSX()"
              mat-menu-item
              [disabled]="dataSourceEmpty"
            >
              <!-- Descargar -->
              <mat-icon>cloud_download</mat-icon>
              <span>{{ "facturas.descargar" | transloco }}</span>
            </button>

            <button
              (click)="printComponent()"
              mat-menu-item
              [disabled]="dataSourceEmpty"
            >
              <!-- Imprimir -->
              <mat-icon>cloud_download</mat-icon>
              <span>{{ "facturas.imprimir" | transloco }}</span>
            </button>
          </mat-menu>
        </span>
      </div>

      <div *ngFor="let factura of tableToXLSX">
        <ng-container *ngIf="factura | facturasFoundPipe: searchInput">
          <mat-card style="margin-bottom: 10px !important">
            <div dir="rtl" class="estadoMobile">
              <div style="width: 50%">
                <div
                  [ngClass]="
                    'dark-theme'
                      ? 'darkEstado'
                      : 'light-theme'
                      ? 'lightEstado'
                      : ''
                  "
                  style="margin-bottom: 5px"
                >
                  {{ "facturas.select." + factura.descestado | transloco }}
                </div>
              </div>
            </div>
            <mat-list>
              <mat-list-item>
                <div fxLayout="column">
                  <div class="titleItem">
                    {{ "facturas.numero" | transloco }}
                  </div>
                  <div>{{ factura.factura }}</div>
                </div>
              </mat-list-item>
              <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxLayout="column" style="margin-right: 10px">
                    <div class="titleItem">
                      {{ "facturas.vencimiento" | transloco }}
                    </div>
                    <div>{{ factura.fecvenc }}</div>
                  </div>
                  <div fxLayout="column">
                    <div class="titleItem">
                      {{ "facturas.emision" | transloco }}
                    </div>
                    <div>{{ factura.fecemi }}</div>
                  </div>
                </div>
              </mat-list-item>
              <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <!-- <div fxLayout="column" style="margin-right: 10px">
                                            <div class="titleItem">{{ 'facturas.moneda' | transloco }}</div>
                                            <div></div>
                                        </div> -->
                  <div>
                    <div class="titleItem">
                      {{ "facturas.importe" | transloco }}
                    </div>
                    <div fxLayout="row">
                      <div *ngIf="factura.moneda == 'Pesos'">
                        $ {{ factura.importe }}
                      </div>
                      <div *ngIf="factura.moneda == 'U$S Div Vend'">
                        U$S Div Vend {{ factura.importe }}
                      </div>
                      <div *ngIf="factura.moneda == 'U$S'">
                        U$S {{ factura.importe }}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
            <div fxLayoutAlign="end end">
              <button
                color="primary"
                type="button"
                (click)="detalle(factura)"
                mat-raised-button
              >
                {{ "facturas.detalle" | transloco }}
              </button>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </div>
    <scroll-top></scroll-top>
  </div>
</div>
