import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import { DashboardMessageDialogComponent } from './message/message.component';
import { AvatarModule } from 'ngx-avatar';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    AvatarModule,
    TranslocoModule
  ],
  declarations: [
    DashboardComponent,
    DashboardMessageDialogComponent
  ],
  entryComponents:[
    DashboardMessageDialogComponent
  ],
  providers: [
    DashboardService
  ]
})
export class DashboardModule { }
