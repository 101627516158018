import { Pipe, PipeTransform } from '@angular/core';
import { SharedSearchFilterService } from './search-filter.component';


@Pipe({ name: 'notificationsAdminFoundPipe', pure: true })
export class NotificationsAdminFoundPipe implements PipeTransform {

    constructor(public searchFilterService: SharedSearchFilterService) { }

    transform(item: any, input: string): any { return this.find(item, input) }
    find(item: any, input: string): Boolean { return this.searchFilterService.notificationsAdminFilter(item, input) }
}


@Pipe({ name: 'newsFoundPipe', pure: true })
export class NewsFoundPipe implements PipeTransform {

    constructor(public searchFilterService: SharedSearchFilterService) { }

    transform(item: any, input: string): any { return this.find(item, input) }
    find(item: any, input: string): Boolean { return this.searchFilterService.newsFilter(item, input) }
}

@Pipe({ name: 'newsAdminFoundPipe', pure: true})
export class NewsAdminFoundPipe implements PipeTransform {

    constructor(public searchFilterService: SharedSearchFilterService) { }

    transform(item: any, input: string): any { return this.find(item, input) }
    find(item: any, input: string): Boolean { return this.searchFilterService.newsAdminFilter(item, input) }
}

@Pipe({ name: 'facturasFoundPipe', pure: true })
export class FacturasFoundPipe implements PipeTransform {

    constructor(public searchFilterService: SharedSearchFilterService) { }

    transform(item: any, input: string): any { return this.find(item, input) }
    find(item: any, input: string): Boolean { return this.searchFilterService.facturasFilter(item, input) }
}
