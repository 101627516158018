<div fxLayout="column" fxFill>
    <ng-container *transloco="let t; read 'notificaciones'">
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title style="margin-bottom: 15px;">
            <mat-icon class="hidden" aria-hidden="false" aria-label="Example home icon" (click)="closeDialog()">
                arrow_back
            </mat-icon>
            <h3 class="table-header">{{ t('notificaciones')}}</h3>
        </div>
        <tr *ngFor="let item of notificacionesUsuarios">
            <div *ngIf="item?.estado && !item?.solicitado" class="full-width" fxFlex style="margin-bottom: 10px;">
                <mat-card class="full-width" fxFlex fxLayout="row">
                    <div class="display">
                        <img src="../../assets/images/new-notifications-bro.svg" style="width:146px; color: #e0e3f5">
                    </div>
                    <div div fxFlex="100%" style="margin-left: 5px;">
                        <div class="message-time">
                            <div class="fecha-feed">
                                <span class="author" style="font-weight: 500; font-size: 20px">
                                    {{ t('nueva-notificacion')}}
                                </span>
                                <br>
                                {{ item.createdAt | date: formatoFecha}}
                            </div>
                        </div>
                        <div class="message-content line-clamp">

                            <span class="author" style="font-size: 16px;">{{ item.empleado?.nombre }} {{
                                item.empleado?.apellido
                                }}, {{ t('legajo')}}
                                {{ item.empleado?.legajo }},
                                <ng-container *transloco="let e; read 'mensaje'">
                                    {{ e(item.mensaje) }}
                                </ng-container>
                            </span>
                        </div><br>
                        <div style="margin-top: 10px;">
                            <a href="{{item.url}}" style="text-decoration: none;" fxLayoutAlign="end start">
                                <button color="primary" class="mat-button-wrapper" type="button" mat-raised-button>
                                    {{ t('ver-notificacion')}}
                                </button>
                            </a>
                        </div>
                    </div>
                </mat-card>
            </div>
        </tr>
        <ng-container *ngIf="rol=='ADMIN'||rol=='ADMIN_RAIZ'">
            <tr *ngFor="let item of notificacionesAdmin">
                <div *ngIf="item?.estado && item?.solicitado" class="full-width" fxFlex style="margin-bottom: 10px;">
                    <mat-card class="full-width" fxFlex fxLayout="row">
                        <div class="display">
                            <img src="../../assets/images/new-notifications-bro.svg"
                                style="width:146px; color: #e0e3f5">
                        </div>
                        <div div fxFlex="100%" style="margin-left: 5px;">
                            <button mat-icon-button class="close-button" (click)="close(item)"
                                fxLayoutAlign="end start">
                                <mat-icon>close</mat-icon>
                            </button>
                            <div class="message-time">
                                <div class="fecha-feed">
                                    <span class="author" style="font-weight: 500; font-size: 20px;">
                                        {{ t('nueva-notificacion')}}
                                    </span>
                                    <br>
                                    {{ item.createdAt | date: formatoFecha }}
                                </div>
                            </div>

                            <div class="message-content line-clamp">
                                <span class="author" style="font-size: 16px;">{{ item.empleado?.nombre }} {{
                                    item.empleado?.apellido
                                    }}, {{ t('legajo')}}
                                    {{ item.empleado?.legajo }},
                                    <ng-container *transloco="let e; read 'mensaje'">
                                        {{ e(item.mensaje) }}
                                    </ng-container>
                                </span>
                            </div><br>
                            <div style="margin-top: 10px;">
                                <a href="{{item.url}}" style="text-decoration: none;" fxLayoutAlign="end start">
                                    <button color="primary" class="mat-button-wrapper" type="button"
                                        fxLayoutAlign="end start" mat-raised-button>
                                        {{ t('ver-notificacion')}}
                                    </button>
                                </a>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </tr>
        </ng-container>
    </ng-container>
</div>