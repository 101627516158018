import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable()
export class FacturasService {
    public urlFacturas = environment.baseUrl + "consul/facturas/";
    public urlEstados = environment.baseUrl + "consul/estados/";
    iduserppc = localStorage.getItem('iduserppc');
    constructor(public http: HttpClient) { }

    public getFacturas(facturas): Observable<any> {
        let body = {
            "iduserppc": this.iduserppc,
            "idfactura": "",
            "fecdesde": facturas.fecdesde,
            "fechasta": facturas.fechasta,
            "idestado": facturas.idestado
        }
        return this.http.put(this.urlFacturas + 'reng', body);
    }

    public getEstados(): Observable<any> {
        return this.http.get(this.urlEstados + this.iduserppc);
    }

    public getDetalle(id): Observable<any> {
        return this.http.get(this.urlFacturas + 'detalles/' + id);
    }

    public getFactura(id): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(this.urlFacturas + 'archivos/' + id, { headers: headers, responseType: 'blob' });
    }

}