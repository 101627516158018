import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {

  translate;

  constructor(public translateService: TranslocoService) { }

  ngOnInit(): void {
    this.generarTraducciones();
  }

  generarTraducciones() {
    this.translateService.selectTranslateObject('empleados-admin.mensajes').subscribe(traduccion => {
      this.translate = traduccion;
    });
  }

}
